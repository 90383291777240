import { AppConfig, GlideConfig, IDPConfig } from '@virtus/common/auth';

export interface GlideAppConfig extends AppConfig {
  glide: GlideConfig;
  idp: IDPConfig;
  marketmapURLS?: string[];
  routePathsDisabled?: string[]; // Route toggling
  disableLivePrice?: boolean; // Feature toggling
  nexusLinks?: any;
}

const MARKET_MAP_URLS = ['https://www.marketmapmuse.com', 'https://www2.marketmapmuse.com'];

// TODO: Store on .env but a pipeline will need to exist to inject env values

/**
 * returns correct GlideConfig object depending on __ENVIRONMENT__
 *
 * Using "if" statements other environments should be tree-shaked by webpack and not appear in bundled js
 */

console.info('__ENVIRONMENT__', __ENVIRONMENT__);

const getConfig = (): GlideAppConfig => {
  if (__ENVIRONMENT__ === 'dev' || location.hostname.includes('glide-web-dev')) {
    const GLIDE_ENV_BASE_URL = 'glide-dev.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideDev',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-dev',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-local',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-dev') {
    const GLIDE_ENV_BASE_URL = 'glide-dev.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:5000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideDev',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-local',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'release' || location.hostname.includes('glide-web-release')) {
    const GLIDE_ENV_BASE_URL = 'glide.qa.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8090`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8092`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebRelease',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-release',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-release') {
    const GLIDE_ENV_BASE_URL = 'glide-web-release';
    return {
      glide: {
        baseURL: `http://localhost:8080`,
        API_WEB_SOCKET_URL: `ws://localhost:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebRelease',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-local',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-qa') {
    const GLIDE_ENV_BASE_URL = 'glide.qa.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-local',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  // TODO: The config should be populated via environment variable in
  // CI: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72433
  if (__ENVIRONMENT__ === 'BSP' || location.hostname.includes('glide-web-benefitstreet')) {
    const GLIDE_ENV_BASE_URL = 'bsp-http.alphakinetic.com';
    const GLIDE_ENV_WS_BASE_URL = 'bsp-ws.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_WS_BASE_URL}`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}/dashboard/api`,
      },

      // This is a quick fix to disable some feature for clients.
      // This should be addressed properly with: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72173
      routePathsDisabled: ['deals', 'credit'],
      disableLivePrice: true,
      nexusLinks: {
        compliance: 'https://nexus.virtusconnect.com/compliance',
        reporting: 'https://nexus.virtusconnect.com/reporting',
      },

      // TBC: Taufique
      idp: {
        baseURL: 'https://login.fisglobal.com/idp',
        firm: 'ben160551',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-bsp',
        serviceProviderId: 'glide-bsp',
        idleTimeout: 20 * 60 * 1000,
      },
      // Need whitelist for EC2 BSP
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'qa' || location.hostname.includes('glide-web-qa')) {
    const GLIDE_ENV_BASE_URL = 'glide.qa.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-qa',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'demo' || location.hostname.includes('glide-web-demo')) {
    const GLIDE_ENV_BASE_URL = 'demo.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebDemo',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'demo',
        serviceProviderId: 'glide-demo',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'commerzbank') {
    const GLIDE_ENV_BASE_URL = 'commerzbank.alphakinetic.com';
    return {
      // Temporary config for IDP testing integration
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login.fisglobal.com/idp',
        firm: 'Commerzbankprod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-commerzbank',
        serviceProviderId: 'glide-commerzbank',
        idleTimeout: 20 * 60 * 1000,
      },

      // This is a quick fix to disable some feature for clients.
      // This should be addressed properly with: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72173
      routePathsDisabled: ['dashboard', 'deals', 'credit'],

      disableLivePrice: true,
      nexusLinks: {
        compliance: 'https://nexus.virtusconnect.com/compliance',
        reporting: 'https://nexus.virtusconnect.com/reporting',
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'demo-release' || location.hostname.includes('glide-web-demo-release')) {
    const GLIDE_ENV_BASE_URL = 'demo-release.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebDemoRelease',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'demo-release',
        serviceProviderId: 'glide-demo-release',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'albacore-test' || location.hostname.includes('glide-web-albacore-test')) {
    const GLIDE_ENV_BASE_URL = 'albacore-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'fid185768uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore-test',
        serviceProviderId: 'glide-albacore-test',
        idleTimeout: 20 * 60 * 1000,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'albacore' || location.hostname.includes('glide-web-albacore')) {
    const GLIDE_ENV_BASE_URL = 'albacore.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login4.fisglobal.com/idp',
        firm: 'alba185768prod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore',
        serviceProviderId: 'glide-albacore',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-albacore-test') {
    return {
      glide: {
        baseURL: 'http://localhost:8080', // albacore-test.alphakinetic.com
        API_WEB_SOCKET_URL: 'ws://localhost:8082',
        environment: 'albacore-test',
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'fid185768uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore-test',
        serviceProviderId: 'glide-albacore-local',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-albacore') {
    return {
      glide: {
        baseURL: 'http://localhost:8080', // albacore-test.alphakinetic.com
        API_WEB_SOCKET_URL: 'ws://localhost:8082',
        environment: 'albacore',
      },
      idp: {
        baseURL: 'https://login4.fisglobal.com/idp',
        firm: 'alba185768prod',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore',
        serviceProviderId: 'glide-albacore',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-api-qa') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-local',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-api-ksc-test') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'king62984uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ksc-test',
        serviceProviderId: 'glide-ksc-local',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'ksc-test' || location.hostname.includes('glide-web-ksc-test')) {
    const GLIDE_ENV_BASE_URL = 'ksc-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'king62984uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ksc-test',
        serviceProviderId: 'glide-ksc-test',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-api-metlife-test') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'met171186uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'metlife-test',
        serviceProviderId: 'glide-metlife-local',
        idleTimeout: 0,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'metlife-test' || location.hostname.includes('glide-web-metlife-test')) {
    const GLIDE_ENV_BASE_URL = 'metlife-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'met171186uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'metlife-test',
        serviceProviderId: 'glide-metlife-test',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-api-ft-test' || location.hostname.includes('local-api-ft-test')) {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'franklin133608uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ft-test',
        serviceProviderId: 'glide-ft-local',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'ft-test' || location.hostname.includes('glide-web-ft-test')) {
    const GLIDE_ENV_BASE_URL = 'ft-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'franklin133608uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ft-test',
        serviceProviderId: 'glide-ft-test',
        idleTimeout: 0,
      },
    };
  }

  if (
    __ENVIRONMENT__ === 'nw-test-internal' ||
    (location.hostname.includes('glide-web-nw-test') && location.search.includes('internal'))
  ) {
    const GLIDE_ENV_BASE_URL = 'nw-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'NW228127',
        clientId: 'GlideWebInternal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'nw-test',
        serviceProviderId: 'glide-nw-test-internal',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'nw-test' || location.hostname.includes('glide-web-nw-test')) {
    const GLIDE_ENV_BASE_URL = 'nw-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'NW228127',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'nw-test',
        serviceProviderId: 'glide-nw-test',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'spc-test' || location.hostname.includes('glide-web-spc-test')) {
    const GLIDE_ENV_BASE_URL = 'spc-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'silverpointcapital',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'spc-test',
        serviceProviderId: 'glide-spc-test',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'acm-test' || location.hostname.includes('glide-web-acm-test')) {
    const GLIDE_ENV_BASE_URL = 'acm-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'AdventCapUAT',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'acm-test',
        serviceProviderId: 'glide-acm-test',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-api-dev') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideDev',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-local',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'ksc' || location.hostname.includes('glide-web-ksc.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'ksc.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'king62984prod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ksc',
        serviceProviderId: 'glide-ksc',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'ft' || location.hostname.includes('glide-web-ft.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'ft.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'franklin133608prod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ft',
        serviceProviderId: 'glide-ft',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'acm' || location.hostname.includes('glide-web-acm.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'acm.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'AdventCapitalManagement',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'acm',
        serviceProviderId: 'glide-acm',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'spc' || location.hostname.includes('glide-web-spc.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'spc.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'silverpointcapital',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'spc',
        serviceProviderId: 'glide-spc',
        idleTimeout: 0,
      },
    };
  }

  if (
    __ENVIRONMENT__ === 'nw-internal' ||
    (location.hostname.includes('glide-web-nw.alphakinetic.com') && location.search.includes('internal'))
  ) {
    const GLIDE_ENV_BASE_URL = 'nw.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'NW228127',
        clientId: 'GlideWebInternal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'nw',
        serviceProviderId: 'glide-nw-internal',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'nw' || location.hostname.includes('glide-web-nw.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'nw.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'NW228127',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'nw',
        serviceProviderId: 'glide-nw',
        idleTimeout: 0,
      },
    };
  }

  if (__ENVIRONMENT__ === 'metlife' || location.hostname.includes('glide-web-metlife.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'metlife.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'met171186prod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'metlife',
        serviceProviderId: 'glide-metlife',
        idleTimeout: 0,
      },
    };
  }

  throw new Error(`No config for __ENVIRONMENT__:${__ENVIRONMENT__} or hostname:${location?.hostname}`);
};

console.info('Active config name:', __ENVIRONMENT__ || location.hostname);
console.info('Active config details:', getConfig());

export default getConfig();
